import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { baseURl } from "BaseUrl";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "react-bootstrap";
import axios from "axios";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import CountUp from "react-countup";

function UseCase() {
  const [cases, setCase] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("180deg");
  const [isLoading, setIsLoading] = useState(false);

  const useCaseData = async () => {
    setIsLoading(true);
    axios.get(`${baseURl}/admin/use-case-statistics/monthly?search=${search}`).then((response) => {
      let arr = [...response?.data?.code_use_cases, ...response?.data?.images_use_cases, ...response?.data?.text_use_cases, ...response?.data?.audio_use_cases, ...response?.data?.video_use_cases];
      let arr1 = arr.sort((a, b) => b.count - a.count);
      setCase(arr1);
      setIsLoading(false);
    });
  };

  const sorting = () => {
    cases.reverse();
    setSort(sort === "180deg" ? "0deg" : "180deg");
  };

  useEffect(() => {
    useCaseData();
  }, [search]);
  console.log(cases, "cases")
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card sx={{ overflow: "auto" }}>
                  <MDBox
                    mx={2}
                    mt={2}
                    py={1.5}
                    px={1.5}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <MDTypography variant="h5" color="white">
                      Use Case
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox sx={{ margin: "10px 20px" }}>
                      <Table>
                        <thead>
                          <tr style={{ color: "#7b809a", textTransform: "uppercase", fontSize: "0.65rem", cursor: "pointer" }} onClick={() => sorting()}>
                            <th>
                              Use Case <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                            <th>
                              Language <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                            <th>
                              Style <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                            <th>
                              Count <ArrowDropUpRoundedIcon style={{ transform: `rotate(${sort})` }} fontSize="medium" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cases?.map((item, index) => (
                            <tr key={index} style={{ color: "#7b809e", fontSize: "0.85rem" }}>
                              <td>{item?.useCase ? item?.useCase.replace("Create", "Generate Image") : "Code"}</td>
                              <td>{item?.language}</td>
                              <td>{item?.style ? item?.style : "-"}</td>
                              <td>
                                <CountUp end={item?.count} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default UseCase;
