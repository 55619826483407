import { useState, useEffect } from "react";
import axios from "axios";
import { baseURl } from "BaseUrl";

import Grid from "@mui/material/Grid";
import moment from "moment";
import { Typography, Card, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import LoadingAnimation from "components/Animation/LoadingAnimation";

const Texts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userData();
  }, [count]);

  const userData = async () => {
    try {
      const res = await axios.get(
        `${baseURl}/textGeneration/getGeneratedTexts?page=${count}`
      );
      const allUsers = res.data.data;
      setData([...data, ...allUsers]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!loading ? (
        <DashboardLayout>
          <DashboardNavbar />
          {data?.map((items, index) => {
            return (
              <Card sx={{ mb: 5 }}>
                <MDBox py={2} px={2} lineHeight={1.25} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/profile/${items?.user?._id}`)}
                  >
                    <Box>
                      <MDAvatar
                        src={items?.user?.image}
                        alt="profile-image"
                        size="lg"
                        shadow="sm"
                      />
                    </Box>
                    <Grid container>
                      <Grid item xs={4}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {items?.user?.name}
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight={400}
                          color="text"
                        >
                          {items?.user?.email}
                          {items?.user?.email_verified_at ||
                          items?.user?.plan_start ? (
                            <Tooltip
                              title={
                                items?.user?.plan_start
                                  ? "subscriber"
                                  : "verified"
                              }
                            >
                              <MDBadge
                                badgeContent=<CheckCircleIcon />
                                color={
                                  items?.user?.plan_start ? "info" : "success"
                                }
                                variant="gradient"
                                size="sm"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="not verified">
                              <MDBadge
                                badgeContent=<CancelIcon />
                                color="error"
                                variant="gradient"
                                size="sm"
                              />
                            </Tooltip>
                          )}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" color="text" fontWeight="400">
                          {`Language : ${items?.language}`}
                        </Typography>
                        <Typography variant="h6" color="text" fontWeight="400">
                          {`Use Case : ${items?.useCase}`}
                        </Typography>
                        <MDTypography variant="button" color="text">
                          {moment(items?.createdAt).format(
                            "DD/MM/YYYY,h:mm:ss a"
                          )}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Box>
                </MDBox>
                <MDBox px={2}>
                  <Grid container spacing={0}>
                    {items?.text?.choices?.map((text, index) => {
                      return (
                        <Grid item xs={12}>
                          <DefaultProjectCard
                            description={`${index + 1} - ${text.text}`}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </MDBox>
              </Card>
            );
          })}
          <Button
            onClick={() => setCount(count + 1)}
            color="white"
            variant="contained"
          >
            More
          </Button>
        </DashboardLayout>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default Texts;
