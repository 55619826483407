import { useState, useEffect } from "react";
import axios from "axios";
import { baseURl } from "BaseUrl";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Typography, Tooltip } from "@mui/material";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, Button } from "@mui/material";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import MDBadge from "components/MDBadge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";

const Images = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userData();
  }, [count]);

  const userData = async () => {
    try {
      const res = await axios.get(
        `${baseURl}/textGeneration/getGeneratedImages?page=${count}`
      );
      const allUsers = res.data.data;
      setData([...data, ...allUsers]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!loading ? (
        <DashboardLayout>
          <DashboardNavbar />

          {data?.map((items, index) => {
            return (
              <>
                <Card sx={{ mt: 2 }}>
                  <Box py={2} px={2} lineHeight={1.25} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/profile/${items?.user?._id}`)}
                    >
                      <Box mr={2}>
                        <MDAvatar
                          src={items?.user?.image}
                          alt="profile-image"
                          size="lg"
                          shadow="sm"
                        />
                      </Box>
                      <Grid container spacing={2}>
                        <Grid xs={4}>
                          <Typography variant="h6" fontWeight="500">
                            {items?.user?.name}
                          </Typography>
                          <Typography variant="h6" fontWeight="500">
                            {items?.user?.email}
                            {items?.user?.email_verified_at ||
                            items?.user?.plan_start ? (
                              <Tooltip
                                title={
                                  items?.user?.plan_start
                                    ? "subscriber"
                                    : "verified"
                                }
                              >
                                <MDBadge
                                  badgeContent=<CheckCircleIcon />
                                  color={
                                    items?.user.plan_start ? "info" : "success"
                                  }
                                  variant="gradient"
                                  size="sm"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="not verified">
                                <MDBadge
                                  badgeContent=<CancelIcon />
                                  color="error"
                                  variant="gradient"
                                  size="sm"
                                />
                              </Tooltip>
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={8}>
                          <MDBox mb={1}>
                            <MDTypography
                              variant="h6"
                              color="text"
                              fontWeight="200"
                            >
                              {`Prompts : ${items.prompt}`}
                            </MDTypography>
                            <MDTypography variant="button" color="text">
                              {moment(items.createdAt).format(
                                "DD/MM/YYYY,h:mm:ss a"
                              )}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <MDBox p={2}>
                    <Grid container spacing={6}>
                      {items?.urls?.map((images, index) => {
                        return (
                          <Grid item xs={12} md={6} xl={3}>
                            <DefaultProjectCard
                              image={images}
                              label={index + 1}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </MDBox>
                </Card>
              </>
            );
          })}

          <Button
            onClick={() => setCount(count + 1)}
            color="white"
            variant="contained"
          >
            More
          </Button>
        </DashboardLayout>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default Images;
