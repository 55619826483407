import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import affiliateUsers from "./data/affiliateUsers";
import allPayout from "./payout/allPayout";
import { useState } from "react";
import { Switch } from "@mui/material";
function Affilliate() {
  var { columns, rows, data, loading, search, setSearch, refresh, setRefresh, setCount, pageLimit, limit, setLimit, count, total } = affiliateUsers();

  var {
    payoutColumns,
    payoutRows,
    payoutData,
    payoutLoading,
    payoutSearch,
    payoutSetSearch,
    payoutRefresh,
    payoutSetRefresh,
    payoutSetCount,
    payoutPageLimit,
    payoutLimit,
    payoutSetLimit,
    payoutCount,
    payoutTotal,
  } = allPayout();

  const navigate = useNavigate();
  const [showPayout, setShowPayout] = useState(false);
  const onClickFn = (user) => {
    navigate(`/affilliate/${user?.user?._id}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={2}
                py={1.5}
                px={1.5}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h5" color="white">
                  affiliate Table
                </MDTypography>
                <MDBox style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <MDTypography style={{color:"white"}}>
                  Payout Request
                </MDTypography>
                <Switch
                  checked={showPayout}
                  onChange={() => {
                    setShowPayout(!showPayout)
                  }}
                />
                </MDBox>
                {/* <MDButton onClick={() => setShowPayout(!showPayout)}>Payout Request</MDButton> */}
                <MDButton
                  onClick={() => {
                    if (showPayout) {
                      payoutSetRefresh(payoutRefresh + 1);
                    } else {
                      setRefresh(refresh + 1);
                    }
                  }}
                  variant="contained"
                  color="info"
                  endIcon={<RefreshRoundedIcon />}
                >
                  Refresh
                </MDButton>
              </MDBox>
              <MDBox>
                {(showPayout ? !payoutLoading : !loading) ? (
                  showPayout ? (
                    <DataTable
                      table={{ columns: payoutColumns, rows: payoutRows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                      pagination={true}
                      search={payoutSearch}
                      setSearch={payoutSetSearch}
                      canSearch={true}
                      datas={payoutData}
                      pageLimit={payoutPageLimit}
                      limit={payoutLimit}
                      setLimit={payoutSetLimit}
                      count={payoutCount}
                      setCount={payoutSetCount}
                      total={payoutTotal}
                    // onClickFn={onClickFn}
                    />
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={true}
                      showTotalEntries={true}
                      noEndBorder
                      pagination={true}
                      search={search}
                      setSearch={setSearch}
                      canSearch={true}
                      datas={data}
                      pageLimit={pageLimit}
                      limit={limit}
                      setLimit={setLimit}
                      count={count}
                      setCount={setCount}
                      total={total}
                      onClickFn={onClickFn}
                    />
                  )
                ) : (
                  <LoadingAnimation />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Affilliate;
