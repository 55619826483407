import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import { payoutRequest } from "apis/affilliate";
import { changeRequestStatus } from "apis/affilliate";
export default function allPayout() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [pageLimit, setPageLimit] = useState();
  const [search, setSearch] = useState("");
  const [status,setStatus] = useState("");
  const [id,setId] = useState("")
  useEffect(() => {
    allPayout();
  }, [refresh, count, limit, search]);

  const allPayout = async () => {
    try {
      setLoading(true);
      const res = await payoutRequest(count, limit);
      const allPayout = res?.data?.data?.docs;
      setData(allPayout);
      const total = res?.data?.data?.totalDocs;
      setTotal(total);
      const pLimit = Math.floor(total / limit + 1);
      setPageLimit(pLimit);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(()=>{
    let datastatus= {
      "status":status
    }
    if(id){
      processRequest(datastatus)
    }
  },[status])
  async function processRequest(datastatus) {
    try {
      const response = await changeRequestStatus(id, datastatus);
    } catch (error) {
      alert(error.response.data.message)
    }
  }
  return {
    payoutColumns: [
      { Header: "Email", accessor: "email", align: "left" },
      {
        Header: "Payout code",
        accessor: "code",
        align: "left",
        width: "20%",
      },
      { Header: "Requested Amount", accessor: "earning", align: "left" },
      { Header: "Received At", accessor: "total_earning", align: "center" },
      { Header: "Status", accessor: "usage", align: "center" },
      { Header: "Create at", accessor: "created", align: "center" },
    ],

    payoutRows: data?.map((item) => ({
      //   row: item,
      email: (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar src={item?.user?.image} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {item?.user?.name}
            </MDTypography>
            <MDTypography variant="caption">{item?.user?.email}</MDTypography>
          </MDBox>
        </MDBox>
      ),

      code: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {item?.code?.code}
        </MDTypography>
      ),
      earning: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          $ {item?.paidAmount}
        </MDTypography>
      ),
      total_earning: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(item?.receivedAt).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      usage: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <select value={item?.status} style={{padding:"5px",outline:"none", borderRadius:"10px"}} onChange={(e)=>{setId(item?._id); setStatus(e.target.value)}} >
          <option value="received">received</option>
          <option value="declined">declined</option>
          <option value="requested">requested</option>
          </select>
          {/* {item?.status} */}
        </MDTypography> 
      ),
      created: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(item?.createdAt).format("DD/MM/YYYY")}
        </MDTypography>
      ),
    })),
    payoutData: data,
    payoutLoading: loading,
    payoutSearch: search,
    payoutSetSearch: setSearch,
    payoutRefresh: refresh,
    payoutSetRefresh: setRefresh,
    payoutSetCount: setCount,
    payoutPageLimit: pageLimit,
    payoutLimit: limit,
    payoutSetLimit: setLimit,
    payoutCount: count,
    payoutTotal: total,
  };
}
