import Axios from "axios";
const BaseURL = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const token = localStorage.getItem("token");
if (token) {
  BaseURL.defaults.headers.common["Authorization"] = `${token}`;
}
BaseURL.defaults.headers.post["Content-Type"] = "application/json";

export const getAffilliate = (count, limit) => {
  return BaseURL.get(`/affiliate/get?page=${count}&limit=${limit}`);
};

export const getAffilliateById = (count, limit, id) => {
  return BaseURL.get(
    `/affiliate/admin/code/usage?&page=${count}&limit=${limit}&user_id=${id}`
  );
};

export const getAffilliateByCode = (count, limit, code) => {
  return BaseURL.get(
    `/affiliate/admin/code/payment?&page=${count}&limit=${limit}&user_id=${code}`
  );
};

export const payoutRequest = (count, limit) => {
  return BaseURL.get(
    `/affiliate-payout/request/admin/get?page=${count}&limit=${limit}&status`
  );
};

export const payoutRequestById = (id) => {
  return BaseURL.get(`affiliate-payout/request/admin/get/${id}`);
};

export const changePayoutStatus = (id, data) => {
  return BaseURL.put(`/affiliate-payout/request/admin/update/${id}`, data);
};
export const changeRequestStatus = (id, data) => {
  return BaseURL.put(`/affiliate-payout/request-status/${id}`, data);
};
