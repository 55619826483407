import React, { useState, useEffect, useRef } from "react";
import { baseURl } from "BaseUrl";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import logo from "assets/images/XDream Logo.svg"
// import logo from "../../assets/images/Aai-logo.png";
import MDBox from "components/MDBox";
import { Card, Box, IconButton, TextField, TableContainer, Table, TableBody, TableRow, TableCell, TablePagination, Paper, TableHead, Stack, Button } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { Switch, Typography, Input, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MDBadge from "components/MDBadge";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DoneIcon from "@mui/icons-material/Done";
import LoadingAnimation from "components/Animation/LoadingAnimation";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "layouts/profile/data/UserTable";
import UserTable from "./data/UserTable";
import GptDetailsModal from "./gptDetailsModal";

function Overview() {
  const inputRef = useRef(null);
  const [image, setImage] = useState(false);
  const [userSubsToggle, setUserSubsToggle] = useState(false);
  const [text, setText] = useState(false);
  const [data, setData] = useState();
  const [edit, setEdit] = useState(false);
  const [userSubs, setUserSubs] = useState([]);
  const [useDetails, setUseDetails] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [change, setChange] = useState({
    texts: "",
    images: "",
  });
  const [loading, setLoading] = useState(true);
  const [successSB, setSuccessSB] = useState(false);
  const [gptHistory, setGptHistory] = useState(false);
  const [gptHistoryData, setGptHistoryData] = useState({
    count: 0,
    data: [],
  });

  const [pages, setPages] = useState(1);
  const [showGptHisoryModal, setShowGptHisoryModal] = useState(false);
  const [modalData, setModalData] = useState({
    count: 0,
    data: [],
  });

  const [gptNext, setGptNext] = useState(1);

  const [width, setWidth] = useState(window.innerWidth);

  const getsubscriptiondata = async () => {
    try {
      const res = await axios.get(`${baseURl}/subscription/get/user/${params.id}?limit=${limit}&page=${page}`);
      const subs = res.data.subscriptions.docs;
      const totalDocs = res.data.subscriptions.totalDocs;
      const totalPages = res.data.subscriptions.totalPages;
      const hasNextPage = res.data.subscriptions.hasNextPage;
      const hasPrevPage = res.data.subscriptions.hasPrevPage;
      setUserSubs(subs);
      setLimit(limit);
      setUseDetails({
        totalDocs,
        limit,
        totalPages,
        hasNextPage,
        hasPrevPage,
      });

      return subs;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width, page]);

  // To access a users subscription data

  const params = useParams();

  const getUserById = async () => {
    try {
      const res = await axios.get(`${baseURl}/user/admin/getUserDetail/${params.id}`);
      const UserByID = res.data;
      setData(UserByID);
      setChange({
        ...change,
        images: UserByID?.user?.image_count_left,
        texts: UserByID?.user?.text_count_left,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUserById();
  }, [successSB]);

  // useEffect(() => {
  //   getsubscriptiondata();
  // }, []);

  useEffect(() => {
    if (userSubsToggle) {
      getsubscriptiondata();
    }
    return;
  }, [limit, page]);

  let token = localStorage.getItem("token");

  const resendEmail = async () => {
    try {
      const response = await axios.post(
        `${baseURl}/auth/admin/user/send_verification_email`,
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setSuccessSB(true);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const verifyUser = async () => {
    try {
      const response = await axios.put(`${baseURl}/auth/admin/user/verify/${params.id}`, {
        headers: {
          Authorization: token,
        },
      });
      setSuccessSB(true);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${baseURl}/auth/admin/user/update/${params.id}`,
        {
          text_count_left: change.texts,
          image_count_left: change.images,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setEdit(false);
      setSuccessSB(true);
      // Return the updated user details
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getGptHistory = async () => {
    try {
      const response = await axios.get(`${baseURl}/gpt-chat-admin/user/${params.id}?page=${pages}&limit=10`, {
        headers: {
          Authorization: token,
        },
      });
      setGptHistoryData({
        count: response.data.count,
        data: response.data.data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getGptHistory();
  }, [pages]);

  useEffect(() => {
    handleGetGptHistoryByID();
  }, [gptNext]);

  const [refIdState, setRefId] = useState();

  const handleGetGptHistoryByID = async (refId) => {
    setRefId(refId);
    try {
      const response = await axios.get(`${baseURl}/gpt-chat-admin/message/${refId || refIdState}?page=${gptNext}&limit=10`, {
        headers: {
          Authorization: token,
        },
      });

      setModalData({
        count: response.data.count,
        data: response.data.data,
      });
      console.log(response.data, "gpt history");
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      <DashboardLayout>
        <MDSnackbar color="success" icon="check" title="Success" content="User updated successfully" open={successSB} onClose={() => setSuccessSB(false)} close={() => setSuccessSB(false)} bgWhite />
        {!loading ? (
          <>
            <DashboardNavbar />
            <MDBox mb={2} />

            <MDBox position="relative" mb={5}>
              <Card
                sx={{
                  position: "relative",
                  mt: 2,
                  mx: 3,
                  py: 2,
                  px: 2,
                }}
              >
                <Grid container spacing={3} alignItems="center">
                  <Grid item>
                    <MDAvatar src={data?.user?.image ? data?.user?.image : logo} alt="" size="xl" shadow="xs" />
                  </Grid>
                  <Grid item>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {data?.user?.name ? data?.user?.name : "Not Available"}
                      </MDTypography>
                      <MDTypography variant="button" color="text" fontWeight="regular"></MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mt={5} mb={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Card sx={{ boxShadow: "none" }}>
                        <Box
                          p={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                            Plan Details
                          </MDTypography>
                          <Box>
                            {edit ? (
                              <IconButton
                                onClick={() => {
                                  setEdit(!edit);
                                }}
                              >
                                <CloseIcon sx={{ cursor: "pointer" }} />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  setEdit(!edit);
                                }}
                              >
                                <EditRoundedIcon sx={{ cursor: "pointer" }} />
                              </IconButton>
                            )}
                            {edit && (
                              <IconButton onClick={handleSave}>
                                <DoneIcon sx={{ cursor: "pointer" }} />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                        <Box px={2} py={1} sx={{ display: "flex" }}>
                          <Typography variant="h6" fontWeight={"700"}>
                            Plan :{" "}
                          </Typography>
                          <Typography variant="h6" color="primary" pl={2}>
                            {data?.user?.plan}
                          </Typography>
                        </Box>

                        <Box px={2} py={1} sx={{ display: "flex" }}>
                          <Typography variant="h6" fontWeight={"700"}>
                            Account Type :
                          </Typography>
                          <Typography variant="h6" color="primary" pl={2}>
                            {data?.user?.enterprise ? "Enterprise Account" : "Normal Account"}
                          </Typography>
                        </Box>

                        <Box px={2} py={1} sx={{ display: "flex" }}>
                          <Typography variant="h6" fontWeight={"300"}>
                            Images Left :
                          </Typography>
                          {edit ? (
                            <TextField
                              ref={inputRef}
                              sx={{
                                fontWeight: "800",
                                fontSize: "1.1rem",
                                ml: 2,
                              }}
                              value={change.images}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setEdit(false);
                                }
                              }}
                              onChange={(e) => setChange({ ...change, images: e.target.value })}
                            ></TextField>
                          ) : (
                            <Typography variant="h6" pl={2}>
                              {change.images}
                            </Typography>
                          )}
                        </Box>
                        <Box px={2} py={1} sx={{ display: "flex" }}>
                          <Typography variant="h6" fontWeight={"300"}>
                            Texts Left :
                          </Typography>
                          {edit ? (
                            <TextField
                              sx={{
                                fontWeight: "800",
                                fontSize: "1.1rem",
                                ml: 2,
                              }}
                              value={change.texts}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setEdit(false);
                                }
                              }}
                              onChange={(e) => setChange({ ...change, texts: e.target.value })}
                            ></TextField>
                          ) : (
                            <Typography variant="h6" pl={2}>
                              {change.texts}
                            </Typography>
                          )}
                        </Box>
                        <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
                          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                            <MDBox mt={0.5} px={2}>
                              {data?.image.length}
                              <Switch checked={image} onChange={() => setImage(!image)} />
                            </MDBox>
                            <MDBox width="80%" ml={0.5}>
                              <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                Images
                              </MDTypography>
                            </MDBox>
                          </MDBox>

                          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                            <MDBox mt={0.5} px={2}>
                              {data?.text.length}
                              <Switch checked={text} onChange={() => setText(!text)} />
                            </MDBox>
                            <MDBox width="80%" ml={0.5}>
                              <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                Texts
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                            <MDBox mt={0.5} px={2}>
                              {gptHistoryData.count}
                              <Switch checked={gptHistory} onChange={() => setGptHistory(!gptHistory)} />
                            </MDBox>
                            <MDBox width="80%" ml={0.5}>
                              <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                Xdream Gpt
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox p={2}>
                        <Grid item xs={12}>
                          <MDBox>
                            <MDTypography variant="h5">Profile Info</MDTypography>
                          </MDBox>
                          <Box p={1} mt={3} sx={{ display: "flex" }}>
                            <Typography variant="h6" fontWeight={"700"}>
                              Email :{" "}
                            </Typography>
                            <Typography variant="h6" fontWeight={"00"} pl={2}>
                              {data?.user?.email ? data?.user?.email : "Not Available"}
                            </Typography>
                            {data?.user?.email_verified_at || data?.user?.plan_start ? (
                              <Tooltip title={data?.user?.plan_start ? "subscriber" : "verified"}>
                                <MDBadge badgeContent=<CheckCircleIcon /> color={data?.user.plan_start ? "info" : "success"} variant="gradient" size="sm" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="not verified">
                                <MDBadge badgeContent=<CancelIcon /> color="error" variant="gradient" size="sm" />
                              </Tooltip>
                            )}
                            {!data?.user?.email_verified_at && (
                              <Tooltip title="Send verification email" sx={{ cursor: "pointer" }} onClick={resendEmail}>
                                <MDBadge badgeContent=<RestartAltIcon /> color="dark" variant="gradient" size="sm" />
                              </Tooltip>
                            )}
                            {!data?.user?.email_verified_at && (
                              <Tooltip title="Verify email" sx={{ cursor: "pointer" }} onClick={verifyUser}>
                                <MDBadge badgeContent=<DoneIcon /> color="success" variant="gradient" size="sm" />
                              </Tooltip>
                            )}
                          </Box>
                          <Box p={1} sx={{ display: "flex" }}>
                            <Typography variant="h6" fontWeight={"700"}>
                              Mobile :
                            </Typography>
                            <Typography variant="h6" fontWeight={"300"} pl={2}>
                              {data?.user?.phone_number ? data?.user?.phone_number : "Not Available"}
                            </Typography>
                          </Box>
                          <Box p={1} sx={{ display: "flex" }}>
                            <Typography variant="h6" fontWeight={"700"}>
                              Account Created :
                            </Typography>
                            <Typography variant="h6" fontWeight={"300"} pl={2}>
                              {moment(data?.user?.createdAt).format("DD/MM/YYYY,h:mm:ss a")}
                            </Typography>
                          </Box>
                          <Box p={1} sx={{ display: "flex" }}>
                            <Typography variant="h6" fontWeight={"700"}>
                              Verified :
                            </Typography>
                            <Typography variant="h6" fontWeight={"300"} pl={2}>
                              {data?.user?.email_verified_at ? moment(data?.user?.email_verified_at).format("DD/MM/YYYY,h:mm:ss a") : "Not Verified"}
                            </Typography>
                          </Box>
                          {data?.user?.plan_start && (
                            <>
                              <Box p={1} sx={{ display: "flex" }}>
                                <Typography variant="h6" fontWeight={"600"}>
                                  Plan Started :
                                </Typography>
                                <Typography variant="h6" fontWeight={"300"} pl={2}>
                                  {moment(data?.user?.plan_start).format("DD/MM/YYYY,h:mm:ss a")}
                                </Typography>
                              </Box>
                              <Box p={1} sx={{ display: "flex" }}>
                                <Typography variant="h6" fontWeight={"600"}>
                                  Plan Expires :{" "}
                                </Typography>
                                <Typography variant="h6" fontWeight={"300"} pl={2}>
                                  {moment(data?.user?.plan_expiry).format("DD/MM/YYYY,h:mm:ss a")}
                                </Typography>
                              </Box>
                            </>
                          )}
                          <MDBox p={2}>
                            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                              <MDBox mt={0.5} px={2}>
                                <Switch
                                  checked={userSubsToggle}
                                  onChange={() => {
                                    setUserSubsToggle(!userSubsToggle);
                                    getsubscriptiondata();
                                  }}
                                />
                              </MDBox>
                              <MDBox width="80%" ml={0.5}>
                                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                  Transaction
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
            {image && (
              <>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Images
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={6}>
                    {data?.image?.map((items, index) => {
                      return (
                        <React.Fragment key={index}>
                          {items?.urls?.map((images, index) => {
                            return (
                              <Grid key={index} item xs={12} md={6} xl={3}>
                                <Card>
                                  <DefaultProjectCard image={images} />
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Typography variant="h6" px={2}>
                                        {` Prompts : ${items.prompt}`}
                                      </Typography>
                                      <Typography variant="h6" px={2}>
                                        {`Time : ${moment(items.createdAt).format("ddd DD MMM YYYY - h:mm:ss a")}`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </MDBox>
              </>
            )}
            {text && (
              <>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Texts
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={6}>
                    {data?.text?.map((items, index) => {
                      return (
                        <React.Fragment key={index}>
                          {items?.text?.choices?.map((text, index) => {
                            return (
                              <Grid key={index} item xs={12}>
                                <Card>
                                  <Typography
                                    sx={{
                                      whiteSpace: "break-spaces",
                                      direction: items?.language === "Arabic" ? "rtl" : "ltr",
                                      fontWeight: "400",
                                    }}
                                    variant="h6"
                                    mt={2}
                                    mb={2}
                                    px={2}
                                  >
                                    {text.text}
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <Typography variant="h6" px={2}>
                                        {` Word Count : ${items.word_count}`}
                                      </Typography>
                                      <Typography variant="h6" px={2}>
                                        {`Time : ${moment(items.createdAt).format("ddd DD MMM YYYY - h:mm:ss a")}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Typography variant="h6" px={2}>
                                        {`Language : ${items.language}`}
                                      </Typography>
                                      <Typography variant="h6" px={2} pb={2}>
                                        {`Use Case : ${items.useCase}`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </MDBox>
              </>
            )}
            {gptHistory && (
              <>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Xdream Gpt
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              width: "50%",
                            }}
                          >
                            <Typography variant="h6">Title</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gptHistoryData?.data?.map((items, index) => (
                          <TableRow
                            key={index}
                            onClick={() => {
                              handleGetGptHistoryByID(items.ref_id);
                              setShowGptHisoryModal(true);
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <TableCell
                              sx={{
                                whiteSpace: "break-spaces",
                                fontWeight: "400",
                                pl: 4,
                              }}
                            >
                              <Typography variant="h6">{items.title}</Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                whiteSpace: "break-spaces",
                                fontWeight: "400",
                              }}
                            >
                              <Typography variant="h6">{moment(items.createdAt).format("ddd DD MMM YYYY - h:mm:ss a")}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                        {gptHistoryData?.count > 10 && (
                          <Stack gap={2} direction={"row"}>
                            <Button
                              disabled={pages <= 1}
                              onClick={() => {
                                setPages(pages - 1);
                              }}
                            >
                              Prev
                            </Button>
                            <Button
                              disabled={pages === Math.round(gptHistoryData?.count / 10)}
                              onClick={() => {
                                setPages(pages + 1);
                              }}
                            >
                              Next
                            </Button>
                          </Stack>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </>
            )}
            {userSubsToggle ? (
              <Card sx={{ p: 2, minWidth: "930px", overflow: "auto" }}>
                <MDBox
                  mx={0}
                  my={2}
                  mt={2}
                  py={1.5}
                  px={1.5}
                  variant="gradient"
                  bgColor="secondary"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MDTypography variant="h5" color="white">
                    Transactions
                  </MDTypography>
                </MDBox>

                <Grid item xs={12} md={8}>
                  <UserTable data={userSubs} isLoading={loading} width={width} details={useDetails} page={page} limit={limit} setLimit={setLimit} setPage={setPage} />
                </Grid>
              </Card>
            ) : null}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </DashboardLayout>
      <GptDetailsModal openModal={showGptHisoryModal} handleCloseModal={() => setShowGptHisoryModal(false)} data={modalData} gptNext={gptNext} setGptNext={setGptNext} setRefId={setRefId} />
    </>
  );
}

export default Overview;
